
import { Data, get, post } from '../networking';

export async function postCheckoutInfo(payload?: Data): Promise<Data> {
    return await post('users/checkout-info', payload);
}

export async function getStripePrices(): Promise<Data> {
    return await get('payments-gateway/stripe/prices');
}

export async function getCurrencyList(): Promise<Data> {
    return await get('payments-gateway/stripe/currency-list');
}

export async function postCheckoutSession(payload: Data): Promise<Data> {
    return await post('payments-gateway/stripe/new-checkout-session', payload);
}