import { GetServerSideProps, GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import dynamic from 'next/dynamic';
import { useCallback, useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import Cookies from 'cookies';
import { PrismicHomePageData } from '../utils/prismic/types/pages/home.type';
import { PrismicPaginatedResponse } from '../utils/prismic/types';
import prismic from '../utils/prismic';
import { postCheckoutInfo } from '../logic/pricing';
import SessionContext from '../contexts/session';
import { refreshUserAndStoreIn } from '../logic/auth';
import { Data, get } from '../networking';
import { assignTestCookie } from '../logic/abtest';
import { ampIdentifySet, ampLogEvent } from '../lib/amplitude';

async function getCouponInfo(id?: string, context?: GetServerSidePropsContext): Promise<Data> {
    if (!id) {
        return;
    }
    return await get(`payments-gateway/stripe/coupon/${id}`, {}, context);
}

export const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext) => {
    const host = context.req.headers.host;
    const cookies = new Cookies(context.req, context.res);
    const couponId = cookies.get('ss_discount') || context.query.d;
    const couponResponse = await getCouponInfo(couponId, context);
    if (host && host.includes('app.')) {
        return {
            props: {
                couponResponse: couponResponse || {},
                metaData: {
                    disableNoIndex: false,
                },
                host: context.req.headers.host,
                query: context.query
            }
        };
    }

    let testId = cookies.get('exp_homepage_240417') || null;
    testId = assignTestCookie(context, cookies, 'exp_homepage_240417', testId);

    const data = await prismic().get('homepage', null, true, false) as PrismicPaginatedResponse<PrismicHomePageData>;

    return {
        props: {
            couponResponse: couponResponse || {},
            data: data,
            metaData: {
                ogTitle: data?.results[0].data.meta_title,
                ogSiteName: 'Sunnyside',
                ogUrl: 'https://www.sunnyside.co',
                ogDescription: data?.results[0].data.meta_description,
                ogImage: 'https://images.prismic.io/sunnysidetest/63e6349a-7e7b-4ce2-9841-54c570ebcda7_sunnyside-og.jpg?auto=compress,format',
                title: data?.results[0].data.meta_title,
                disableNoIndex: true,
            },
            host: context.req.headers.host,
            query: context.query,
            testId
        }
    };
};

const DynamicAppHome = dynamic(() => import('./app_index'));
const DynamicHome = dynamic(() => import('./www_index'));

export default function HomePage({
    couponResponse,
    data,
    host,
    query,
    testId
}: InferGetServerSidePropsType<typeof getServerSideProps>): JSX.Element {
    const [cookies, setCookie] = useCookies();
    const session = useContext(SessionContext);

    // return app if app
    if (host.includes('app.')) {
        return <DynamicAppHome query={query} couponResponse={couponResponse} />;
    }

    if (testId === 'exp_homepage_240417_unbounce_new3') {
        let url = 'https://get.sunnyside.co/new3/';
        useEffect(() => {
            Object.entries(query).forEach((entry, index) => {
                url += (`${index === 0 ? '?' : '&'}${entry[0]}=${entry[1]}`);
            });
            window.location.assign(url);
        });
        return (<></>);
    }

    // expires in one hour
    const expiresAt = new Date(new Date().getTime() + (60 * 60 * 1000));

    const postCheckoutData = async () => {
        const payload = {
            coupon: query.d,
        };
        await postCheckoutInfo(payload);
    };

    useEffect(() => {
        ampLogEvent(
            'active: added to experiment',
            {
                'experiment_name': 'exp_homepage_240417',
                'experiment_variant': 'exp_homepage_240417_baseline'
            },
            cookies
        );
        ampIdentifySet(
            'exp_homepage_240417',
            'exp_homepage_240417_baseline'
        );
        const domain = (new URL(process.env.NEXT_PUBLIC_SUNNYSIDE_API_HOST));
        let processedDomain;
        if (domain.hostname === 'localhost') {
            processedDomain = 'localhost';
        } else {
            processedDomain = '.' + domain.hostname.match(/(\S+\.)?([\S]+\.com?)$/)[2];
        }
        if (query && query.d) {
            setCookie('ss_discount', query.d, {
                path: '',
                expires: expiresAt,
                domain: processedDomain
            });
            refreshUserAndStoreIn(session);
            if (session.user) {
                postCheckoutData();
            }
        }
    }, []);

    return (
        <DynamicHome couponResponse={couponResponse} data={data} />
    );
}
